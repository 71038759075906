import { API_URL } from '../utils/global';

const axiosDef = require('axios').default;

export const defaultErrorHandler = (axiosError) => {
  let error = null;
  if (axiosError.response && axiosError.response.data) {
    error = axiosError.response.data.errors
      ? axiosError.response.data.errors
      : axiosError.response.data.message
      ? axiosError.response.data.message
      : 'unknown_error';
  } else {
    error = axiosError.message ? axiosError.message : 'unknown_error';
  }

  if (
    axiosError.config.url !== '/login/' &&
    axiosError.response &&
    axiosError.response.status === 401
  ) {
    // localStorage.removeItem('sl_auth');
    window.location.reload();
  }
  return Promise.reject({ message: error, status: axiosError.response.status });
};

export const defaultSuccesHandler = (response) => {
  return Promise.resolve(response.data);
};

export function createApi({
  baseURL = `${API_URL}`,
  timeout = 20000,
  headers = { 'Content-Type': 'application/json' },
  withCredentials = true,
  errorHandler = defaultErrorHandler,
  successHandler = defaultSuccesHandler,
  axios = axiosDef.create(),
}) {
  axios.defaults.baseURL = baseURL;
  axios.defaults.timeout = timeout;
  axios.defaults.headers = headers;
  axios.defaults.withCredentials = withCredentials;
  axios.defaults.validateStatus = (status) => {
    return status < 300;
  };
  axios.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
  );
  return axios;
}

export const authApi = createApi({
  baseURL: `${API_URL}/system/`,
});

export const mainApi = createApi({
  baseURL: `${API_URL}/userinterface/get_main_design/`,
});

export const genericApi = createApi({
  baseURL: `${API_URL}`,
});

export const translationApi = createApi({
  baseURL: `${API_URL}/system/translation_ng/UI_NG/sl-main`,
});

export const systemApi = createApi({
  baseURL: `${API_URL}/system`,
});
