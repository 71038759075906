import {
  NEW_CONSOLE_OUTPUT,
  SET_CONSOLE_OUTPUT,
  SET_ALERT,
  SET_NOTIFICATION,
  BACKEND_VERSION,
  SET_COLOR,
  CONFIG_VARS,
} from './types/ContextActionTypes';
import { systemApi, genericApi } from '../utils/api';

export const doGetBackendVersion = (dispatch) => {
  return systemApi
    .get('/about')
    .then((response) => {
      dispatch(setBackendVersion(response.data.version));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const doChangeWorkstation = (value) => (dispatch) => {
  document.cookie = `work_sess_wks=${value};path=/;`;
};

export const doAlert = (type, title, value) => (dispatch) => {
  dispatch(alert(type, title, value));
};

export const doNotification = (type, title, value) => (dispatch) => {
  dispatch(notification(type, title, value));
};

// export const setColor = (value) => (dispatch) => {
//   dispatch(color(value));
// };

// export const color = (value) => {
//   return {
//     type: SET_COLOR,
//     value,
//   };
// };

export const doGetConfigVars = (dispatch) => {
  return genericApi
    .post('/sl_main_backend/get_config_vars/', {
      vars: ['SL_MAIN_PASSWORD_RESET_ENABLE', 'SL_MAIN_PASSWORD_RESET_URL'],
    })
    .then((response) => {
      dispatch(setConfigVars(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const setBackendVersion = (data) => {
  return {
    type: BACKEND_VERSION,
    data,
  };
};

export const clear = () => {
  return {
    type: NEW_CONSOLE_OUTPUT,
  };
};

export const setConsoleOutput = (data) => {
  return {
    type: SET_CONSOLE_OUTPUT,
    data,
  };
};

export const alert = (alertType, title, value) => {
  return {
    type: SET_ALERT,
    alertType,
    title,
    value,
  };
};

export const notification = (notType, title, value) => {
  return {
    type: SET_NOTIFICATION,
    notType,
    title,
    value,
  };
};

export const setColor = (value) => {
  return {
    type: SET_COLOR,
    value,
  };
};

export const setConfigVars = (data) => {
  return {
    type: CONFIG_VARS,
    data,
  };
};
