import React, { useEffect } from 'react';
import Main from './components/Main';
import Login from './Login';
import { useDispatch, useSelector } from 'react-redux';
import { callStatus } from './redux/constants';
import { doGetLogin } from './redux/AuthActions';
import { importCliProvider } from './utils/dynamic-imports-sl-cli';
import { setConsoleOutput } from './redux/ContextAction';
import commandDef from './redux/cli/commandsDef.json';
import { doExecCommand } from './redux/cli/commandActions';
import packageJson from '../package.json';
import { doGetBackendVersion, doGetConfigVars } from './redux/ContextAction';
import { doGetTranslations } from './redux/TranslationAction';

const CliProvider = importCliProvider();

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const context = useSelector(({ context }) => context);
  const translations = useSelector(({ translations }) => translations);

  useEffect(() => {
    if (auth.status === callStatus.PENDING) {
      dispatch(doGetLogin());
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    doGetBackendVersion(dispatch);
    doGetTranslations(dispatch);
    doGetConfigVars(dispatch);
    // eslint-disable-next-line
  }, []);

  if (auth.status !== callStatus.SUCCESS) {
    return (
      <div style={{ height: '100vh' }}>
        <Login />
      </div>
    );
  }

  return (
    <CliProvider
      commands={commandDef}
      doCommands={doExecCommand}
      consoleOutput={context.consoleOutput}
      doConsoleOutput={setConsoleOutput}
      translations={translations.data.cli}
      version={{
        frontend: packageJson.version,
        backend: context.backendVersion,
      }}
    >
      <Main />
    </CliProvider>
  );
};

export default App;
