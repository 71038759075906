import { LOGIN, CHANGE_PASSWORD } from './types/AuthActionTypes';
import { callStatus } from './constants';

const initialState = {
  data: {},
  password: {
    status: callStatus.PENDING,
    error: null,
  },
  status: callStatus.PENDING,
  error: null,
};

const AuthReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case LOGIN.IDLE:
      newState = {
        ...state,
        status: callStatus.PENDING,
      };
      return newState;

    case LOGIN.IN_PROGRESS:
      newState = {
        ...state,
        status: callStatus.IN_PROCESS,
      };
      return newState;

    case LOGIN.RESOLVED:
      newState = {
        ...state,
        status: callStatus.SUCCESS,
        data: action.payload,
      };
      return newState;

    case LOGIN.REJECTED:
      newState = {
        ...state,
        status: callStatus.FAILED,
        error: action.payload,
      };
      return newState;

    case LOGIN.FAILED_GET:
      newState = {
        ...state,
        status: callStatus.FAILED_GET,
        error: action.payload,
      };
      return newState;

    case LOGIN.EXPIRED:
      newState = {
        ...state,
        status: callStatus.EXPIRED,
      };
      return newState;

    case LOGIN.REFRESH:
      newState = {
        ...state,
        status: callStatus.REFRESH,
        data: action.payload,
      };
      return newState;

    case CHANGE_PASSWORD.IDLE:
      newState = {
        ...state,
        password: {
          status: callStatus.PENDING,
          error: null,
        },
      };
      return newState;

    case CHANGE_PASSWORD.RESOLVED:
      newState = {
        ...state,
        password: {
          status: callStatus.SUCCESS,
          error: null,
        },
      };
      return newState;

    case CHANGE_PASSWORD.REJECTED:
      newState = {
        ...state,
        password: {
          status: callStatus.FAILED,
          error: action.payload,
        },
      };
      return newState;
    default:
      return state;
  }
};

export default AuthReducer;
