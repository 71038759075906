import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { callStatus } from './redux/constants';
import { doLogin as AuthLogin } from './redux/AuthActions';
import { doLogin as SessionLogin } from './redux/SessionActions';

import { Input, Button, Spin, Alert, Form } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const Main = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ImageCointainer = styled.div`
  margin-bottom: 20px;
`;
const CustomAlert = styled(Alert)`
  margin-top: 20px;
`;
const CustomButton = styled(Button)`
  width: 100%;
  span {
    color: white;
  }
`;
const Login = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const session = useSelector(({ session }) => session);
  const context = useSelector(({ context }) => context);
  const translations = useSelector(({ translations }) => translations);

  const [form] = Form.useForm();

  const handleSubmit = async (e) => {
    e.preventDefault();

    form.validateFields().then((values) => {
      if (
        session.status === callStatus.EXPIRED ||
        session.status === callStatus.FAILED
      ) {
        dispatch(
          SessionLogin({ username: values.user, password: values.password })
        );
      } else {
        dispatch(
          AuthLogin({ username: values.user, password: values.password })
        );
      }
    });
  };

  let userInput = useRef(null);

  useEffect(() => {
    if (userInput.current) {
      userInput.current.focus();
    }
  }, [auth]);

  return (
    <>
      <Main>
        {auth.status === callStatus.IN_PROCESS ||
        session.status === callStatus.IN_PROCESS ? (
          <Spin />
        ) : (
          <Container>
            <ImageCointainer>
              <img src={`./smartlis.png`} alt="" />
            </ImageCointainer>
            <Form form={form} onFinish={handleSubmit} style={{ width: '100%' }}>
              <Form.Item
                name="user"
                rules={[
                  {
                    required: true,
                    message: 'Please input your user!',
                  },
                ]}
                hasFeedback
                style={{ marginBottom: '15px', height: '40px' }}
              >
                <Input
                  prefix={<UserOutlined className="input-icon" />}
                  placeholder={translations.data.login.username}
                  onPressEnter={handleSubmit}
                  ref={userInput}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
                style={{ marginBottom: '15px', height: '40px' }}
              >
                <Input.Password
                  prefix={<LockOutlined className="input-icon" />}
                  type="password"
                  placeholder={translations.data.login.password}
                  onPressEnter={handleSubmit}
                />
              </Form.Item>
            </Form>

            <CustomButton
              type="primary"
              onClick={handleSubmit}
              style={{ marginBottom: '15px' }}
            >
              {translations.data.login.login}
            </CustomButton>
            {context.configVars &&
              Boolean(context.configVars.SL_MAIN_PASSWORD_RESET_ENABLE) && (
                <a
                  href={`${context.configVars.SL_MAIN_PASSWORD_RESET_URL}`}
                  style={{ fontWeight: 'bold', color: '#3c99f6' }}
                >
                  {translations.data.login.forgot_password}
                </a>
              )}
            {auth.status === callStatus.FAILED ||
            session.status === callStatus.FAILED ? (
              <CustomAlert
                message={
                  auth.status === callStatus.FAILED ? auth.error : session.error
                }
                type="error"
                showIcon
              />
            ) : (
              false
            )}
          </Container>
        )}
      </Main>
    </>
  );
};

export default Login;
