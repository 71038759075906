import React, { useEffect, useRef, useState } from 'react';
import './Nav.css';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { Menu, Dropdown } from 'antd';
import { css } from '@emotion/css';
import colors from '../constants/colors';
import { useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ContainerNav = styled.div`
  flex: 1;
`;

const ContainerSubmenu = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 36px;

  position: absolute;
`;
const ItemMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border-bottom: 3px solid transparent;
`;
const Nav = ({ modules, handleSetUrl, activeItem }) => {
  var icons = require('@mdi/js');
  const [submenu, setSubmenu] = useState({ dist: 0, data: [] });

  const navRef = useRef(null);
  const subRef = useRef(null);
  const context = useSelector(({ context }) => context);
  const { color } = context;

  let timeout = false;

  useEffect(() => {
    const updateMenu = () => {
      if (navRef.current) {
        const tamMax =
          submenu.data.length > 0
            ? navRef.current.clientWidth - 35
            : navRef.current.clientWidth;

        let tam = 0;
        let dist = 0;
        let auxSubmenu = [];
        modules &&
          modules.forEach((item) => {
            var itemDiv = document.getElementById(`item-${item.id}`);
            if (itemDiv) {
              tam = tam + itemDiv.clientWidth + 15;

              if (tam > tamMax) {
                auxSubmenu.push(item);
              } else {
                dist = tam;
              }
            }
          });

        if (!isEqual(auxSubmenu, submenu.data)) {
          setSubmenu({ dist: dist, data: auxSubmenu });
        }
      }
    };

    updateMenu();

    window.addEventListener('resize', function () {
      clearTimeout(timeout);
      // eslint-disable-next-line
      timeout = setTimeout(updateMenu, 500);
    });
  });

  const subMenuDrop = (
    <Menu
      className={css`
        background-color: ${colors.header.nav.menu[color]};

        .ant-dropdown-menu-item:hover {
          background-color: ${colors.header.nav.menu[color]};
          border-left: 3px solid white;
        }
        padding-right: 8px;
      `}
    >
      {submenu.data.map((item) => (
        <Menu.Item
          key={item.id}
          onClick={() => handleSetUrl(item)}
          style={{
            borderLeft: item.id === activeItem.id && '3px solid white',
          }}
        >
          <span>{item.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Container>
      <ContainerNav>
        <nav
          ref={navRef}
          style={{
            backgroundColor: colors.header.nav[color],
          }}
          className={css`
            ul li {
              background-color: ${colors.header.nav.li[color]};
              border-bottom: 3px solid ${colors.header.nav.li[color]};
            }
            li:hover {
              border-bottom: 3px solid ${colors.header.nav.menu.hover[color]};
              cursor: pointer;
            }
          `}
        >
          <ul>
            {modules &&
              modules.map((item) => {
                let CustomIcon = item.image ? icons[item.image] : null;
                if (!item.alignment || item.alignment === 'left') {
                  return (
                    <li
                      id={`item-${item.id}`}
                      key={`item-${item.id}`}
                      onClick={() => handleSetUrl(item)}
                      style={{
                        borderBottom:
                          item.id === activeItem.id && '3px solid white',
                        visibility: submenu.data.find(
                          (value) => value.id === item.id
                        )
                          ? 'collapse'
                          : 'visible',
                      }}
                    >
                      {/* {item.image ? (
                        <div className="info">
                          <div className="info-text">
                            <div style={{ paddingTop: '5px' }}>
                              <Icon
                                path={CustomIcon}
                                size={'18px'}
                                color="white"
                              />
                            </div>
                            <span style={{ marginLeft: '5px' }}>
                              {item.name}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="info">
                          <div className="info-text">
                            <div style={{ paddingTop: '5px', width: '0px' }}>
                              <Icon path="" size={'18px'} color="white" />
                            </div>
                            <span>{item.name}</span>
                          </div>
                        </div>
                      )} */}

                      <div className="info">
                        <div className="info-text">
                          <div style={item.image ? {} : { width: '0px' }}>
                            <Icon
                              path={item.image ? CustomIcon : ''}
                              size={'18px'}
                              color="white"
                              style={{ verticalAlign: 'middle' }}
                            />
                          </div>
                          <div
                            style={
                              item.image && item.name
                                ? { paddingTop: '2px', marginLeft: '5px' }
                                : { paddingTop: '2px' }
                            }
                          >
                            {item.name}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
          </ul>
        </nav>
      </ContainerNav>
      {submenu.data.length > 0 && (
        <ContainerSubmenu
          ref={subRef}
          style={{
            zIndex: 5,
            left: submenu.dist + 50,
          }}
        >
          <Dropdown overlay={subMenuDrop} placement="bottomRight">
            <ItemMore
              style={{
                borderBottom:
                  submenu.data.some((item) => item.id === activeItem.id) &&
                  '3px solid white',
              }}
            >
              <Icon
                path={mdiDotsHorizontal}
                title=""
                size={'20px'}
                color="white"
              />
            </ItemMore>
          </Dropdown>
        </ContainerSubmenu>
      )}
    </Container>
  );
};
export default Nav;
