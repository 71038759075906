import { SESSION } from './types/SessionActionTypes';
import { callStatus } from './constants';

const initialState = {
  data: {},
  status: callStatus.PENDING,
  error: null,
};

const SessionReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SESSION.IDLE:
      newState = {
        ...state,
        status: callStatus.PENDING,
        error: null,
      };
      return newState;

    case SESSION.IN_PROGRESS:
      newState = {
        ...state,
        status: callStatus.IN_PROCESS,
      };
      return newState;

    case SESSION.RESOLVED:
      newState = {
        ...state,
        status: callStatus.SUCCESS,
        data: action.payload,
        error: null,
      };
      return newState;

    case SESSION.REJECTED:
      newState = {
        ...state,
        status: callStatus.FAILED,
        error: action.payload,
      };
      return newState;

    case SESSION.EXPIRED:
      newState = {
        ...state,
        status: callStatus.EXPIRED,
        error: null,
      };
      return newState;

    case SESSION.REFRESH:
      newState = {
        ...state,
        status: callStatus.REFRESH,
        data: action.payload,
        error: null,
      };
      return newState;

    default:
      return state;
  }
};

export default SessionReducer;
