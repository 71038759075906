import React, { useEffect } from 'react';
import { Modal, Button, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../constants/colors';
import { css } from '@emotion/css';

import { doChangeWorkstation } from '../redux/ContextAction';
import { getCookie } from '../utils/cookieFunction';

const ChangeWorkstation = ({ closeModal }) => {
  const dispatch = useDispatch();
  const translations = useSelector(({ translations }) => translations);
  const context = useSelector(({ context }) => context);

  const { color } = context;
  const [form] = Form.useForm();

  useEffect(() => {
    const value = getCookie('work_sess_wks');

    form.setFieldsValue({
      wks: value,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    form.validateFields().then((values) => {
      dispatch(doChangeWorkstation(values.wks));
      handleCloseModal();
    });
  };

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <Modal
      visible={true}
      onCancel={() => handleCloseModal()}
      mask={true}
      maskClosable={false}
      title={translations.data.workstation.title_modal}
      footer={[
        <Button
          key="close"
          onClick={() => handleCloseModal()}
          style={{ tabIndex: 1 }}
          className={css`
            &:hover {
              color: ${colors.header.more.input.hover[color]};
              border: 1px solid ${colors.header.more.input.hover[color]};
            }
          `}
        >
          {translations.data.password.close_button}
        </Button>,
        <Button
          key="submit"
          onClick={(e) => handleSubmit(e)}
          style={{ tabIndex: 2 }}
          className={css`
            &:hover {
              color: ${colors.header.more.input.hover[color]};
              border: 1px solid ${colors.header.more.input.hover[color]};
            }
          `}
        >
          {translations.data.password.change_button}
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          name="wks"
          rules={[
            {
              required: true,
              message: translations.data.workstation.wks,
            },
          ]}
          hasFeedback
          style={{ marginBottom: '15px', height: '40px' }}
        >
          <Input
            placeholder={translations.data.workstation.wks}
            onPressEnter={handleSubmit}
            className={css`
              &:hover {
                color: ${colors.header.more.input.hover[color]};
                border: 1px solid ${colors.header.more.input.hover[color]};
              }
            `}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangeWorkstation;
