import { genericApi } from '../utils/api';
import { MAIN, IFRAME, MODULE } from './types/MainActionTypes';
import { API_URL } from '../utils/global';
import store from './store';
import { setColor } from './ContextAction';

const SL_MAIN_BACKEND_GET_MAIN_DESIGN_URL = '/sl_main_backend/get_main_design/';
const USERINTERFACE_GET_MAIN_DESIGN_URL = '/userinterface/get_main_design/';

export const doLoadDesign = async (url, dispatch) => {
  dispatch(mainInProgress());

  const urlApi = url ? url : SL_MAIN_BACKEND_GET_MAIN_DESIGN_URL;
  const href = window.location.href;
  const pathname = window.location.pathname;
  const hash = window.location.hash;

  let parameters = { href, pathname, hash };

  try {
    await checkLocalSettings();
    if (window.slConfig.app) {
      const app = window.slConfig.app;
      parameters = { ...parameters, app };
    }
  } catch (error) {}

  return genericApi
    .post(urlApi, parameters)
    .then((response) => {
      //ICONOS+TEXT - TEXT - ICON - RIGTH MENU
      // const menu = response.data.main_menu.map((item, index) => {
      //   if (index % 2) {
      //     return { ...item, image: 'mdiHome', alignment: 'right' };
      //   } else {
      //     if (index === 4) {
      //       return { ...item };
      //     } else if (index === 8) {
      //       return { ...item, image: 'mdiHome', name: '' };
      //     } else {
      //       return { ...item, image: 'mdiHome', alignment: 'left' };
      //     }
      //   }
      // });

      //ICONOS+TEXT - RIGTH MENU
      // const menu = response.data.main_menu.map((item, index) => {
      //   if (index % 2) {
      //     return { ...item, image: 'mdiHome', alignment: 'right' };
      //   } else {
      //     return { ...item, image: 'mdiHome', alignment: 'left' };
      //   }
      // });

      //ICONOS+TEXT
      // const menu = response.data.main_menu.map((item, index) => {
      //   return { ...item };
      // });

      //ONLY ICON
      // const menu = response.data.main_menu.map((item, index) => {
      //   return { ...item, image: 'mdiHome', name: '' };
      // });

      //TOPBANNER
      // response.data.top_banner = {
      //   bg_color: '#FFFFFF',
      //   right_image: 'https://portal.laboratorija.lv/CL_Logos/CL_Banner2.PNG',
      //   left_image: 'https://portal.laboratorija.lv/CL_Logos/CL_Banner1.PNG',
      // };

      // SEARCH;
      // response.data.search = {
      //   url:
      //     '/admin/my_search/?post={"context":{"query":"#QUERY#:#CURRENT_APP#"}}',
      // };

      // response.data.search = {
      //   url: '/system/mirror/?post={"query":"#QUERY# #CURRENT_APP#"}',
      // };

      // SKIN;
      //  response.data.skin_name = 'green';

      if (response.ret === 'ERROR') {
        if (
          urlApi !== SL_MAIN_BACKEND_GET_MAIN_DESIGN_URL &&
          urlApi !== USERINTERFACE_GET_MAIN_DESIGN_URL
        ) {
          dispatch(mainFailed(response));
          return;
        }
      }

      dispatch(mainSuccess(response.data));
      dispatch(setModules(response.data.main_menu));

      const colors = ['blue', 'grey', 'red', 'green', 'orange'];

      if (response.data.skin_name && colors.includes(response.data.skin_name)) {
        dispatch(setColor(response.data.skin_name.toLowerCase()));
      } else {
        dispatch(setColor('blue'));
      }

      // dispatch(setModules(menu));

      const init = response.data.main_menu.find(
        (value) => value.url !== '' && value.autoexec
      );

      if (init) {
        dispatch(setInitIframe(init));
      }
    })
    .catch((error) => {
      if (
        urlApi === SL_MAIN_BACKEND_GET_MAIN_DESIGN_URL &&
        error.status === 404
      ) {
        doLoadDesign(USERINTERFACE_GET_MAIN_DESIGN_URL, dispatch);
      } else {
        if (error.message === 'unknown_error') {
          error.message = 'Error loading main design';
        }
        dispatch(mainFailed(error));
      }
    });
};

const checkLocalSettings = async () => {
  const response = await fetch('local-settings/config.json');
  window.slConfig = await response.json();
};

export const openTabIframe = () => {
  if (store.getState().main.iframes.find((value) => value.showPanel)) {
    window.open(
      `${
        API_URL +
        store.getState().main.iframes.find((iframe) => iframe.showPanel).url
      }`,
      '_blank'
    );
  }
};

export const doSetModules = (modules) => (dispatch) => {
  dispatch(setModules(modules));
};

export const doSearch = (value, dispatch) => {
  let urlApi = store.getState().main.data.search.url;

  urlApi = urlApi.replaceAll('#QUERY#', value);

  const activeItem = store.getState().main.activeItem;

  urlApi = urlApi.replaceAll(
    '#CURRENT_APP#',
    activeItem.code ? activeItem.code : ''
  );

  var idAleatorio = Math.floor(Math.random() * 1001);

  const data = {
    // querylibrary: null,
    // query: null,
    // uicmdtreenode: null,
    // uiform: null,
    // uiaction: null,
    // uistage: null,
    // uisecurprivileges: null,
    id: `CUSTOM_QUERY${idAleatorio}`,
    code: `CUSTOM_QUERY${idAleatorio}`,
    name: 'Query',
    pos: 33,
    active: true,
    image: '',
    // type: 'LINK',
    autoexec: false,
    url: urlApi,
    data: '',
    // show_search_bar: false,
    // _model: 'UiModule',
    // search_switches: [],
    showPanel: true,
    refresh: 0,
  };

  dispatch(showIframe(data));
};

export const doShowQuery = (dispatch) => {
  const iframes = store.getState().main.iframes;

  const queries = iframes.filter((value) =>
    value.id.startsWith('CUSTOM_QUERY')
  );

  const data = queries[queries.length - 1];

  dispatch(showIframe(data));
};

const mainInProgress = () => {
  return {
    type: MAIN.IN_PROGRESS,
  };
};
const mainFailed = (error) => {
  return {
    type: MAIN.REJECTED,
    payload: error.message,
    error: true,
  };
};
const mainSuccess = (data) => {
  return {
    type: MAIN.RESOLVED,
    payload: data,
  };
};

export const setInitIframe = (data) => {
  return {
    type: IFRAME.SET_INIT,
    payload: data,
  };
};
export const showIframe = (data) => {
  return {
    type: IFRAME.SHOW,
    payload: data,
  };
};
export const refreshIframe = () => {
  return {
    type: IFRAME.REFRESH,
  };
};
export const duplicateIframe = () => {
  return {
    type: IFRAME.DUPLICATE,
  };
};

export const setModules = (data) => {
  return {
    type: MODULE.SET,
    payload: data,
  };
};
