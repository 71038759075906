import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import AuthReducer from './AuthReducer';
import MainReducer from './MainReducer';
import SessionReducer from './SessionReducer';
import ContextReducer from './ContextReducer';
import TranslationReducer from './TranslationReducer';

export const rootReducer = combineReducers({
  auth: AuthReducer,
  main: MainReducer,
  session: SessionReducer,
  context: ContextReducer,
  translations: TranslationReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
