import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import './More.css';
import {
  Menu,
  Dropdown,
  Tooltip,
  Input,
  // Badge
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/css';
import colors from '../constants/colors';

import ChangePassword from './ChangePassword';
import { doLogout } from '../redux/AuthActions';
import {
  refreshIframe,
  // duplicateIframe,
  // openTabIframe,
  doSearch,
  doShowQuery,
} from '../redux/MainActions';
import Icon from '@mdi/react';
import {
  mdiPower,
  // mdiTab,
  mdiAccount,
  mdiRefresh,
  // mdiTabPlus,
  // mdiOpenInNew,
  mdiLockOutline,
  mdiConsole,
  mdiMagnify,
  mdiDotsVertical,
  mdiMonitorScreenshot,
} from '@mdi/js';
// import { importCliTerminalModal } from '../utils/dynamic-imports-sl-cli';
// const CliTerminalModal = importCliTerminalModal();
import About from './About';
import ChangeWorkstation from './ChangeWorkstation';

const ItemMore = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
  height: 100%;
`;
const ItemMore2 = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 12px;
  border-bottom: 3px solid #1c76d2;
  height: 36px;
  &:hover {
    border-bottom: 3px solid white;
  }
  padding-top: 3px;
`;
const MenuItemText = styled.div`
  margin-left: 7px;
`;

const More = ({ modules, handleSetUrl, activeItem, search }) => {
  const auth = useSelector(({ auth }) => auth);
  const iframes = useSelector(({ main: { iframes } }) => iframes);
  const translations = useSelector(({ translations }) => translations);

  const dispatch = useDispatch();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangeWorkstation, setShowChangeWorkstation] = useState(false);
  const [showCli, setShowCli] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const context = useSelector(({ context }) => context);
  const { color } = context;
  var icons = require('@mdi/js');

  const handleRefresh = () => {
    dispatch(refreshIframe());
  };

  // const handleOpenUrl = () => {
  //   openTabIframe();
  // };

  // const handleDuplicateModule = () => {
  //   dispatch(duplicateIframe());
  // };

  const userMenu = (
    <Menu
      style={{
        paddingRight: '10px',
        backgroundColor: colors.header.more[color],
        color: 'white',
        marginTop: '0px',
        padding: '5px 0px',
      }}
      className={css`
        .ant-dropdown-menu-item:hover {
          background-color: ${colors.header.more.hover[color]};
          border-left: 3px solid white;
        }
      `}
    >
      <Menu.Item
        onClick={() => setShowChangePassword(true)}
        icon={
          <Icon path={mdiLockOutline} title="" size={'20px'} color="white" />
        }
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <MenuItemText>
          {translations.data.navegation.change_password}
        </MenuItemText>
      </Menu.Item>
    </Menu>
  );

  const moduleMenu = (
    <Menu
      style={{
        paddingRight: '10px',
        backgroundColor: colors.header.more[color],
        color: 'white',
        marginTop: '0px',
        padding: '5px 0px',
      }}
      className={css`
        .ant-dropdown-menu-item:hover {
          background-color: ${colors.header.more.hover[color]};
          border-left: 3px solid white;
        }
      `}
    >
      <Menu.Item
        onClick={() => handleRefresh()}
        icon={<Icon path={mdiRefresh} title="" size={'20px'} color="white" />}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <MenuItemText>{translations.data.navegation.refresh}</MenuItemText>
      </Menu.Item>

      <Menu.Item
        onClick={() => setShowCli(true)}
        icon={<Icon path={mdiConsole} title="" size={'20px'} color="white" />}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <MenuItemText>{translations.data.navegation.about}</MenuItemText>
      </Menu.Item>

      {/* <Menu.Item
        onClick={() => handleDuplicateModule()}
        icon={<Icon path={mdiTabPlus} title="" size={'20px'} color="white" />}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <MenuItemText>{translations.data.navegation.duplicate}</MenuItemText>
      </Menu.Item>
      <Menu.Item
        onClick={() => handleOpenUrl()}
        icon={<Icon path={mdiOpenInNew} title="" size={'20px'} color="white" />}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <MenuItemText>{translations.data.navegation.new_tab}</MenuItemText>
      </Menu.Item> */}
    </Menu>
  );

  const handleOnSearch = (e) => {
    doSearch(e.target.value, dispatch);
    setShowSearch(false);
    setInputSearch('');
  };

  const handleOnChangeSearch = (e) => {
    e.preventDefault();
    setInputSearch(e.target.value);
    if (e.target.value !== '') {
      setShowSearch(true);
    }
  };

  const inputSearchRef = useRef(false);

  // const handleOnSearchIcon = (e) => {
  //   e.preventDefault();
  //   const value = inputSearchRef.current.state.value;
  //   doSearch(value, dispatch);
  //   setShowSearch(false);
  //   setInputSearch('');
  // };

  return (
    <>
      {search && (
        <div>
          <Input
            placeholder=""
            ref={inputSearchRef}
            prefix={
              // <Badge
              //   dot={
              //     iframes.filter((value) => value.id.startsWith('CUSTOM_QUERY'))
              //       .length
              //   }
              //   offset={[-3, 5]}
              // >
              <Icon
                path={mdiMagnify}
                title=""
                size={'20px'}
                color={
                  iframes.filter((value) => value.id.startsWith('CUSTOM_QUERY'))
                    .length
                    ? 'white'
                    : '#90a4ae'
                }
                style={{
                  marginLeft: '3px',
                  cursor: 'pointer',
                }}
                // onClick={(e) => handleOnSearchIcon(e)}
                onClick={(e) =>
                  iframes.filter((value) => value.id.startsWith('CUSTOM_QUERY'))
                    .length
                    ? doShowQuery(dispatch)
                    : {}
                }
              />
              // </Badge>
            }
            allowClear
            onPressEnter={(e) => handleOnSearch(e)}
            onClick={() => setShowSearch(true)}
            value={inputSearch}
            onChange={(e) => handleOnChangeSearch(e)}
            onBlur={() => {
              if (inputSearch === '') {
                setShowSearch(false);
              }
            }}
            size="small"
            style={{
              marginLeft: '12px',
              transition: ' width .5s',
              width: showSearch ? '350px' : '120px',
              background: colors.header.more.input[color],

              paddingLeft: 0,
            }}
            className={css`
              .ant-input-clear-icon {
                color: white;
              }
              .ant-input {
                background-color: ${colors.header.more.input[color]} !important;
                color: white;
              }

              &.ant-input-affix-wrapper {
                border: 1px solid ${colors.header.more.input.border[color]} !important;
              }
              &.ant-input-affix-wrapper:hover {
                border: 1px solid ${colors.header.more.hover[color]} !important;
              }
              &.ant-input-affix-wrapper-focused {
                border: 1px solid ${colors.header.more.hover[color]} !important;
              }
            `}
          />
        </div>
      )}
      {modules &&
        modules.map((item, index) => {
          let CustomIcon = item.image
            ? icons[item.image]
            : icons['mdiBorderNoneVariant'];
          if (item.alignment === 'right') {
            return (
              <ItemMore2
                style={{
                  borderBottom: item.id === activeItem.id && '3px solid white',
                }}
                key={index}
              >
                <Tooltip
                  placement="bottom"
                  title={item.name}
                  mouseLeaveDelay={0}
                >
                  <Icon
                    onClick={() => handleSetUrl(item)}
                    path={CustomIcon}
                    title=""
                    size={'20px'}
                    color={'white'}
                  />
                </Tooltip>
              </ItemMore2>
            );
          } else {
            return null;
          }
        })}
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          borderLeft:
            modules &&
            modules.filter((item) => item.alignment === 'right').length > 0
              ? '1px solid #fffffe21'
              : 'none',
          marginLeft:
            modules &&
            modules.filter((item) => item.alignment === 'right').length > 0
              ? '12px'
              : 0,
        }}
      >
        <ItemMore
          style={{
            marginLeft: '12px',
          }}
        >
          <Tooltip
            placement="bottom"
            title={translations.data.navegation.workstation}
            mouseLeaveDelay={0}
          >
            <Icon
              onClick={() => setShowChangeWorkstation(true)}
              path={mdiMonitorScreenshot}
              title=""
              size={'20px'}
              color="white"
              // style={{
              //   marginLeft: '12px',
              //   // marginRight: '3px',
              // }}
            />
          </Tooltip>
        </ItemMore>
        <Dropdown overlay={userMenu}>
          <ItemMore>
            <Icon
              path={mdiAccount}
              title=""
              size={'20px'}
              color="white"
              style={{
                // marginLeft: '12px',
                marginRight: '3px',
              }}
            />
            <span>{auth.data.user ? auth.data.user : auth.data.new_user}</span>
          </ItemMore>
        </Dropdown>

        <Dropdown overlay={moduleMenu}>
          <ItemMore>
            <Icon path={mdiDotsVertical} title="" size={'20px'} color="white" />
          </ItemMore>
        </Dropdown>
        <ItemMore>
          <Tooltip
            placement="bottomRight"
            title={translations.data.navegation.logout}
            mouseLeaveDelay={0}
          >
            <Icon
              onClick={() => dispatch(doLogout())}
              path={mdiPower}
              title=""
              size={'20px'}
              color="white"
            />
          </Tooltip>
        </ItemMore>
      </div>

      {showChangePassword && (
        <ChangePassword closeModal={() => setShowChangePassword(false)} />
      )}

      {showChangeWorkstation && (
        <ChangeWorkstation closeModal={() => setShowChangeWorkstation(false)} />
      )}

      {showCli && <About open={showCli} onCancel={() => setShowCli(false)} />}
    </>
  );
};
export default More;
