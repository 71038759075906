import { authApi } from '../utils/api';
import { SESSION } from './types/SessionActionTypes';
import store from './store';
import { loginSuccess } from './AuthActions';

export const doLogin = (data) => (dispatch) => {
  const user = store.getState().auth.data;
  dispatch(sessionInProgress());
  return authApi
    .post('/login/', { usr: data.username, pwd: data.password })
    .then((response) => {
      const username = user.user ? user.user : user.new_user;
      if (username === response.data.new_user) {
        dispatch(sessionPending());
      } else {
        dispatch(sessionRefresh(response.data));
      }
      dispatch(loginSuccess(response.data));
    })
    .catch((error) => {
      dispatch(sessionFailed(error));
    });
};

export const doExpired = () => (dispatch) => {
  return authApi
    .get('/logout/')
    .then(async (response) => {
      dispatch(sessionExpired());
    })
    .catch((error) => {
      dispatch(sessionFailed(error));
    });
};

export const sessionPending = () => {
  return {
    type: SESSION.IDLE,
  };
};
const sessionInProgress = () => {
  return {
    type: SESSION.IN_PROGRESS,
  };
};
const sessionFailed = (error) => {
  return {
    type: SESSION.REJECTED,
    payload: error.message,
  };
};
// const sessionSuccess = (data) => {
//   return {
//     type: SESSION.RESOLVED,
//     payload: data,
//   };
// };
export const sessionExpired = () => {
  return {
    type: SESSION.EXPIRED,
  };
};
export const sessionRefresh = (data) => {
  return {
    type: SESSION.REFRESH,
    payload: data,
  };
};
