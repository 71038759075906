import React from 'react';
import { DraggableModal } from 'ant-design-draggable-modal';
import { DraggableModalProvider } from 'ant-design-draggable-modal';
import { useSelector } from 'react-redux';
import packageJson from '../../package.json';

import { importCliTerminal } from '../utils/dynamic-imports-sl-cli';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const CliTerminal = importCliTerminal();

const About = ({ open, onCancel }) => {
  const context = useSelector(({ context }) => context);

  return (
    <DraggableModalProvider>
      <DraggableModal
        visible={open}
        onCancel={onCancel}
        // footer={footer(version)}
        footer={false}
        title="About | Smartlis - SL-Main "
        initialWidth={750}
        initialHeight={500}
      >
        <Tabs size="small" defaultActiveKey="1" style={{ height: '100%' }}>
          <TabPane tab="Version" key="1">
            <div style={{ marginTop: '15px' }}>
              <h4>Frontend: {packageJson.version}</h4>
              <h4>Backend: {context.backendVersion}</h4>
            </div>
          </TabPane>
          <TabPane tab="CLI" key="2" style={{ height: '100%' }}>
            <CliTerminal modal={false} />
          </TabPane>
        </Tabs>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default About;
