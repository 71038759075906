import store from '../store';
import {
  doAlert,
  doNotification,
  clear,
  setConsoleOutput,
} from '../ContextAction';

import {
  ALERT,
  CLEAR_CONSOLE,
  HELP,
  NOTIFICATION,
  REFRESH,
  DUPLICATE,
  OPEN_TAB,
} from './commandTypes';
import commandDef from './commandsDef.json';

import { refreshIframe, duplicateIframe, openTabIframe } from '../MainActions';

const cmdAlert = (command) => (dispatch) => {
  switch (command.params.type) {
    case 'success':
    case 'error':
    case 'info':
    case 'warning': {
      dispatch(
        doAlert(command.params.type, command.params.title, command.params.value)
      );
      dispatch(setConsoleOutput('OK'));

      break;
    }
    default: {
      dispatch(
        doNotification(
          'error',
          `Invalid alert type`,
          `'${command.params.type}' is not a valid alert type`
        )
      );
      dispatch(
        setConsoleOutput(
          `ERROR: Invalid alert type ('${command.params.type}' is not a valid alert type)`
        )
      );

      break;
    }
  }
};

const cmdClearConsole = (command) => (dispatch) => {
  dispatch(clear());
};

const cmdHelp = (command) => (dispatch) => {
  const getOutputHelp = (state) => {
    let output = '';
    output = `${state.translations.data.cli.valid_commands}:\n`;

    Object.entries(commandDef.commands).map(([key, value]) => {
      let paramsStr = '';

      value.parameters.map((value) => {
        let param = `${value.acronym ? `${value.acronym}:` : ''}${value.name}`;
        param = !value.mandatory ? `[${param}] ` : `${param} `;
        paramsStr += param;

        if (value.description) {
          paramsStr += `(${value.description}) `;
        }

        return false;
      });

      let command = `        ${value.acronym ? `${value.acronym}:` : ''}${
        value.name
      } ${paramsStr}\n`;

      output += command;

      return false;
    });

    return output;
  };

  dispatch(setConsoleOutput(getOutputHelp(store.getState())));
};

const cmdNotification = (command) => (dispatch) => {
  switch (command.params.type) {
    case 'success':
    case 'error':
    case 'info':
    case 'warning': {
      dispatch(
        doNotification(
          command.params.type,
          command.params.title,
          command.params.value
        )
      );
      dispatch(setConsoleOutput('OK'));

      break;
    }
    default: {
      dispatch(
        doNotification(
          'error',
          `Invalid notification type`,
          `'${command.params.type}' is not a valid notification type`
        )
      );
      dispatch(
        setConsoleOutput(
          `ERROR: Invalid notification type ('${command.params.type}' is not a valid notification type)`
        )
      );

      break;
    }
  }
};

const cmdRefresh = (command) => (dispatch) => {
  dispatch(refreshIframe());
};

const cmdDuplicate = (command) => (dispatch) => {
  dispatch(duplicateIframe());
};

const cmdOpenTab = (command) => {
  openTabIframe();
};

const getCommandText = (command) => {
  const paramsText = Object.entries(command.params)
    .map(([key, value]) => {
      return `${key}:${value}`;
    })
    .join(' ');

  let commandText = `${command.type} ${paramsText}`;

  if (command.commandText) {
    commandText += ` (${command.commandText})`;
  }

  return commandText;
};

export const doAddConsoleLine = (text) => {
  return (dispatch) => {
    try {
      if (Array.isArray(text)) {
        let errors = '';
        text.forEach((elem, index) => {
          errors = errors.concat(
            index > 0 ? `\nError: ${elem.message}` : `Error: ${elem.message}`
          );
        });

        text = errors;
      } else if (typeof text === 'object') {
        text = JSON.stringify(text);
      }

      dispatch(setConsoleOutput(text));
    } catch (error) {
      dispatch(setConsoleOutput(error));
    }
  };
};

export const doExecCommand = (command, optional) => (dispatch, getState) => {
  dispatch(doAddConsoleLine(getCommandText(command)));

  switch (command.type) {
    case ALERT: {
      dispatch(cmdAlert(command));
      break;
    }

    case CLEAR_CONSOLE: {
      dispatch(cmdClearConsole(command));
      break;
    }

    case HELP: {
      dispatch(cmdHelp(command));
      break;
    }

    case NOTIFICATION: {
      dispatch(cmdNotification(command));
      break;
    }

    case REFRESH: {
      dispatch(cmdRefresh(command));
      break;
    }

    case DUPLICATE: {
      dispatch(cmdDuplicate(command));
      break;
    }

    case OPEN_TAB: {
      cmdOpenTab(command);
      break;
    }

    default: {
      dispatch(command);
      if (command.type !== 'clear') {
        dispatch(setConsoleOutput('OK'));
      }
      break;
    }
  }
};
